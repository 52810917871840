<template>
  <div>
    <h2 class="text-left mb-4 mt-4 mid-space-grid " style="margin-top: 0px !important;">
      <span>Alerts</span>
      <span></span>
      <v-btn :disabled="structuredData.length === 0" elevation="2" color="warning" medium :loading="isExportCsving" @click="csvExport">
        Export
      </v-btn>
      <v-dialog v-model="isExportCsving" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Exporting CSV file ...
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </h2>
    <div class="filters-wrap">
      <div v-for="item in filters" :key="item.typeId">
        <v-subheader v-if="item.controlType === 'select'">{{ item.type }}</v-subheader>
        <v-select
          v-if="item.controlType === 'select'"
          :items="filterOpts[item.typeId]"
          :label="'Select ' + item.type"
          dense
          solo
          item-disabled="disabled"
          return-object
          :multiple="item.multiSelect"
          :item-text="item.selectText"
          hide-details="auto"
          style="min-width: 160px; max-width: 260px;"
          v-on:input="e => filterSelect(item.typeId, e)"
          v-model="displayProperty[item.typeId]"
          :disabled="isExportCsving"
        ></v-select>
        <div v-if="item.controlType === 'datepicker' && isCustomDateOpened" class="datepicker-wrap">
          <v-date-picker range v-model="displayProperty[item.typeId]"></v-date-picker>
          <div class="buttons">
            <v-btn v-on:click="closeCustomDateFilter()">Cancel</v-btn>
            <v-btn v-on:click="e => filterSelect(item.typeId, displayProperty['time_date'])">Set</v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-sheet color="white" elevation="20" rounded shaped>
      <v-card>
        <v-card-title>
          <span class="current-selection-sentence" v-html="currSelectionSentence"></span>
          <v-spacer></v-spacer>
          <v-text-field v-if="false" v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="isLoading"
          loading-text="Loading Report Data..."
          :headers="headers"
          :items="structuredData"
          :server-items-length="totalAlertNumber"
          :search="search"
          must-sort
          :sort-desc.sync="sortDescByTime"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          @update:page="paginationChanged"
          @update:items-per-page="paginationChanged"
        ></v-data-table>
      </v-card>
    </v-sheet>
  </div>
</template>
<style scoped lang="scss">
  .datepicker-wrap {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background: white;
    padding-bottom: 20px;
    box-shadow: 0 10px 13px -6px rgba(0,0,0,.2),0 20px 31px 3px rgba(0,0,0,.14),0 8px 38px 7px rgba(0,0,0,.12)!important;

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
    }
  }
  .filters-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
    align-items: flex-start;

    & > div {
      margin-right: 20px;
      min-height: 40px;
    }
  }
  .current-selection-sentence {
    margin-left: 10px;
    font-size: 0.92rem;
  }
</style>
<script>
import { getAlertData, getRoomLocation } from '@/api/devices'
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep, isEmpty } from 'lodash'

const ALERT_TYPE = {
  DETECT_FALL: 'detect_fall',
  REPORT_FALL: 'report_fall',
  EXIT_FALL: 'exit_fall',
  FINISH_FALL: 'finish_fall',
  CANCEL_FALL: 'cancel_fall',
  DETECT_PRESENCE: 'detect_presence',
  DETECT_ABSENCE: 'detect_absence',
  GO_OFFLINE: 'go_offline'
}

export default {
  data () {
    return {
      isLoading: false,
      search: '',
      page: 1,
      itemsPerPage: 10,
      sortDescByTime: true,
      headers: [
        { text: 'Time & Date', value: 'name', sortable: true },
        { text: 'Base', value: 'base', sortable: false },
        { text: 'Location', value: 'locationName', sortable: false },
        { text: 'Room', value: 'deviceContainer', sortable: false },
        { text: 'Alert Type', value: 'alertType' },
        { text: 'Resolution', value: 'resolution', sortable: false },
        { text: 'Device SKU', value: 'deviceSerialNumber', sortable: false }
      ],
      filters: [
        { typeId: 'time_date', selectVal: 'id', selectText: 'name', type: 'Time & Date', multiSelect: false, controlType: 'select' },
        { typeId: 'custom_date', selectVal: 'id', selectText: 'name', type: 'Custom Range', multiSelect: false, controlType: 'datepicker' },
        { typeId: 'room', selectVal: 'id', selectText: 'name', type: 'Room', multiSelect: true, controlType: 'select' },
        { typeId: 'location', selectVal: 'id', selectText: 'name', type: 'Location', multiSelect: true, controlType: 'select' },
        { typeId: 'alert_type', selectVal: 'id', selectText: 'name', type: 'Alert Type', multiSelect: false, controlType: 'select' },
        { typeId: 'resolution', selectVal: 'id', selectText: 'name', type: 'Resolution Type', multiSelect: true, controlType: 'select' },
        { typeId: 'time_of_day', selectedVal: 'id', selectText: 'name', type: 'Time of Day', multiSelect: false, controlType: 'select' }
      ],
      filterOpts: {
        time_date: [
          { id: 'past_hour', name: 'Past Hour', selected: true },
          { id: 'past_twelve', name: 'Past 12 Hours' },
          { id: 'past_day', name: 'Past Day' },
          { id: 'past_three_days', name: 'Past 3 Days' },
          { id: 'past_week', name: 'Past Week' },
          { id: 'past_month', name: 'Past Month' },
          { id: 'past_three_months', name: 'Past 3 Months' },
          { id: 'past_six_months', name: 'Past 6 Months' },
          { id: 'custom_date', name: 'Custom Range' }
        ],
        room: [
          // { name: 'All Rooms', id: 'all_rooms', selected: true }
        ],
        location: [

        ],
        alert_type: [
          // { id: 'all_events', name: 'All Events', selected: true },
          { id: ALERT_TYPE.DETECT_FALL, name: 'detect-fall' },
          { id: ALERT_TYPE.REPORT_FALL, name: 'report-fall' },
          { id: ALERT_TYPE.EXIT_FALL, name: 'exit-fall' },
          { id: ALERT_TYPE.FINISH_FALL, name: 'finish-fall' },
          { id: ALERT_TYPE.CANCEL_FALL, name: 'cancel-fall' },
          { id: ALERT_TYPE.DETECT_PRESENCE, name: 'detect-presence' },
          { id: ALERT_TYPE.DETECT_ABSENCE, name: 'detect-absence' },
          { id: ALERT_TYPE.GO_OFFLINE, name: 'go-offline' }
        ],
        resolution: [
          { id: 'CONFIRMED_WITH_INJURY', name: 'Confirmed w/ Injury' },
          { id: 'CONFIRMED_WITHOUT_INJURY', name: 'Confirmed w/o Injury' },
          { id: 'SIMULATED', name: 'Simulated' },
          { id: 'UNCONFIRMED', name: 'Unconfirmed' }
        ],
        time_of_day: [
          { id: 'Day', name: 'Day Time' },
          { id: 'Night', name: 'Night Time' }
        ]
      },
      displayProperty: {
        room: '',
        location: '',
        alert_type: '',
        time_date: '',
        custom_date: ['', ''],
        resolution: '',
        time_of_day: ''
      },
      structuredLocations: [],
      rawData: [],
      structuredData: [],
      totalAlertNumber: 0,

      unTouchedData: [],
      changeSentence: false,
      currSelectionSentence: '',
      isCustomDateOpened: false,
      isExportCsving: undefined
    }
  },
  computed: {
    ...mapGetters({
      getContainerInfo: 'getContainerInfo',
      getCurrentCustomer: 'login/getCurrentCustomer',
      getCurrentFulfiller: 'login/getCurrentFulfiller'
    })
  },
  async mounted  () {
    await this.reloadData()
  },
  methods: {
    ...mapActions({
      fetchContainers: 'containers/fetchContainers'
    }),
    async dbLoadData (isUpdatedTime = false, chosenFilters = {}) {
      const exportCsv = this.isExportCsving
      this.isLoading = !exportCsv // when export csv ,the table loading is hideing
      const baseData = {
        beginInstant: chosenFilters?.beginInstant ? chosenFilters.beginInstant : this.filterByDateTime('past_hour')
      }
      if (chosenFilters?.endInstant) {
        baseData.endInstant = chosenFilters?.endInstant
      }
      const data = {
        ...baseData,
        exportCsv,
        sortDescByTime: this.sortDescByTime ? -1 : 1,
        phenomenonAction: chosenFilters?.ac ? chosenFilters.ac : ALERT_TYPE.REPORT_FALL,
        room: isEmpty(chosenFilters?.rooms) ? undefined : chosenFilters?.rooms,
        location: isEmpty(chosenFilters?.locations) ? undefined : chosenFilters?.locations,
        resolution: isEmpty(chosenFilters?.resolutions) ? undefined : chosenFilters?.resolutions,
        timeOfDay: isEmpty(chosenFilters?.timeOfDay) ? undefined : chosenFilters?.timeOfDay
      }
      if (!exportCsv) {
        data.limit = this.itemsPerPage === -1 ? null : this.itemsPerPage
        data.offset = (this.page - 1) * this.itemsPerPage
      }

      const promiseList = [getAlertData(data)]
      if (isUpdatedTime && !exportCsv) promiseList.push(getRoomLocation(baseData))
      const resultList = await Promise.all(promiseList)

      if (exportCsv && resultList && resultList[0]) {
        return resultList[0]
      }

      if (resultList && resultList[1]) {
        this.updateRoomLocation(resultList[1].data)
      }

      if (resultList && resultList[0]) {
        const alertData = resultList[0]
        this.rawData = cloneDeep(alertData)
        this.unTouchedData = cloneDeep(alertData)
        this.totalAlertNumber = alertData.data.totalAlertNumber
        this.isLoading = false
      }
    },
    filterAlertData (newVal) {
      const structuredData = []
      for (let i = 0; i < newVal.data.events.length; i++) {
        const event = newVal.data.events[i]?.data
        const meta = newVal.data.events[i]?.meta
        const location = event?.container?.fullyQualifiedName
        const deviceSN = event?.device?.metadata?.serialNumber ?? 'No Device Identifier Found'
        let resolution = event?.resolution?.data?.resolution
        if (resolution === 'REJECTED') {
          resolution = 'UNCONFIRMED'
        }
        if (meta.event.did === 'go-offline') {
          resolution = event?.resolution && this.$t('devices.backOnline') +
            ' @ ' + this.convertDate(new Date(event?.resolution?.meta?.instant))
        } else {
          resolution = event?.resolution &&
            (resolution || '') +
            (resolution ? ' @ ' : '') +
            this.convertDate(new Date(event?.resolution?.meta?.instant)) +
            ' by ' +
            (event?.resolution?.data?.user?.email ? event?.resolution?.data?.user?.email : event?.resolution?.data?.user?.username)
        }
        if (meta.event.did !== 'come-online') {
          structuredData.push({
            name: this.convertDate(new Date(newVal.data.events[i]?.meta?.instant)),
            base: location?.[0],
            locationName: location?.[location?.length - 1],
            deviceContainer: location?.[location?.length - 2],
            alertType: (meta?.event?.did ? 'did-' : '') + meta?.event?.did ?? 'Unknown Alert Type',
            resolution: resolution,
            deviceSerialNumber: deviceSN
          })
        }
      }

      return structuredData
    },
    updateRoomLocation (newVal) {
      const roomData = newVal?.roomNames?.data || []
      const locationData = newVal?.locationNames?.data || []

      this.filterOpts = {
        ...this.filterOpts,
        room: roomData.map(i => ({ id: i, name: i })),
        location: locationData.map(i => ({ id: i, name: i }))
      }
    },
    async filterSelect (type, opt) {
      let answer
      this.page = 1
      switch (type) {
        case 'room':
          this.saveSelectedValue('room', opt, answer)
          await this.reRunRequest()
          break
        case 'location':
          this.saveSelectedValue('location', opt, answer)
          await this.reRunRequest()
          break
        case 'alert_type':
          this.saveSelectedValue('alert_type', opt, answer)
          await this.reRunRequest()
          break
        case 'time_date':
          if (opt.id !== 'custom_date') {
            answer = this.filterByDateTime(opt.id)
            this.saveSelectedValue('time_date', opt, answer)
            this.saveSelectedValue('room', [])
            this.displayProperty['room'] = ''
            this.saveSelectedValue('location', [])
            this.displayProperty['location'] = ''
            this.saveSelectedValue('resolution', [])
            this.displayProperty['resolution'] = ''
            this.saveSelectedValue('time_of_day', [])
            this.displayProperty['time_of_day'] = ''
            await this.reRunRequest(true)
          } else {
            this.isCustomDateOpened = true
          }
          break
        case 'custom_date':
          this.saveSelectedValue('time_date', opt, answer)
          this.saveSelectedValue('room', [])
          this.displayProperty['room'] = ''
          this.saveSelectedValue('location', [])
          this.displayProperty['location'] = ''
          this.saveSelectedValue('resolution', [])
          this.displayProperty['resolution'] = ''
          this.saveSelectedValue('time_of_day', [])
          this.displayProperty['time_of_day'] = ''
          this.isCustomDateOpened = false
          await this.reRunRequest(true)
          break
        case 'resolution':
          answer = await this.filterByResolution('resolution', opt, answer)
          this.saveSelectedValue('resolution', opt, answer)
          await this.reRunRequest()
          break
        case 'time_of_day':
          answer = this.saveSelectedValue('time_of_day', opt, answer)
          await this.reRunRequest()
          break
        default:
          break
      }
      this.changeSentence = !this.changeSentence
    },
    async filterByResolution (alertType, opts, answer) {
      let found = []
      if (opts.length) {
        for (const el of this.unTouchedData?.data.events) {
          let resolution = el?.data?.resolution?.data?.resolution
          if (resolution === 'REJECTED') {
            resolution = 'UNCONFIRMED'
          }
          if (opts.some(op => op?.id === resolution)) {
            found.push(el)
          }
        }
      }
      this.rawData = {}
      this.rawData = { ...this.unTouchedData, data: !opts.length ? this.unTouchedData.data : { events: found } }
    },
    async reRunRequest (isUpdatedTime = false) {
      const chosenTime = this.filterOpts.time_date.find(item => item.selected)
      const chosenActions = this.filterOpts.alert_type.filter(item => item.selected).map(i => i.id).join(',')
      let beginInstant = chosenTime?.value ?? null
      let endInstant = null

      const rooms = this.filterOpts.room.filter(item => item.selected)?.map(i => i.id).join(',')
      const locations = this.filterOpts.location.filter(item => item.selected)?.map(i => i.id).join(',')
      const resolutions = this.filterOpts.resolution.filter(item => item.selected)?.map(i => i.id).join(',')
      const timeOfDay = this.filterOpts.time_of_day.find(item => item.selected)?.id

      if (chosenTime?.id === 'custom_date') {
        this.displayProperty['custom_date'].sort((a, b) => {
          return new Date(a) - new Date(b)
        })
        let beginInstantDate = new Date(this.displayProperty['custom_date'][0])
        beginInstantDate.setHours(0, 0, 0, 0)
        beginInstant = beginInstantDate.toISOString()
        if (this.displayProperty['custom_date'][1]) {
          let endInstantDate = new Date(this.displayProperty['custom_date'][1])
          endInstantDate.setHours(23, 59, 59, 999)
          endInstant = endInstantDate.toISOString()
        }
      }
      return await this.dbLoadData(isUpdatedTime, {
        beginInstant,
        endInstant,
        ac: chosenActions ?? '',
        rooms,
        locations,
        resolutions,
        timeOfDay
      })
    },
    convertDate (myDate) {
      return myDate.toLocaleString('en-US', { hour12: false, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, timeZoneName: 'short' })
    },
    saveSelectedValue (alertType, opt, answer) {
      for (let i = 0; i < this.filterOpts[alertType].length; i++) {
        // First checks if opt is array and contains current id, if opt is not an array it checks the object's id
        if ((opt.length && opt.some(el => el.id === this.filterOpts[alertType][i].id)) || this.filterOpts[alertType][i].id === opt.id) {
          this.filterOpts[alertType][i].value = answer
          this.filterOpts[alertType][i].selected = true
        } else {
          this.filterOpts[alertType][i].selected = false
        }
      }
    },
    filterByDateTime (curr) {
      let currDate = new Date()
      let manipDate
      switch (curr) {
        case 'past_hour':
          manipDate = currDate.setHours(currDate.getHours() - 1)
          break
        case 'past_twelve':
          manipDate = currDate.setHours(currDate.getHours() - 12)
          break
        case 'past_day':
          manipDate = currDate.setDate(currDate.getDate() - 1)
          break
        case 'past_three_days':
          manipDate = currDate.setDate(currDate.getDate() - 3)
          break
        case 'past_week':
          manipDate = currDate.setDate(currDate.getDate() - 7)
          break
        case 'past_month':
          manipDate = currDate.setMonth(currDate.getMonth() - 1)
          break
        case 'past_three_months':
          manipDate = currDate.setMonth(currDate.getMonth() - 3)
          break
        case 'past_six_months':
          manipDate = currDate.setMonth(currDate.getMonth() - 6)
          break
        case 'custom_date':
          manipDate = currDate
          break
      }
      return new Date(manipDate).toISOString()
    },
    async csvExport () {
      this.isExportCsving = true
      try {
        const alertData = await this.reRunRequest(false)
        const structuredData = this.filterAlertData(alertData)
        let header = this.headers.map(el => el.text)
        let rows = structuredData.map(el => Object.values(el))
        let allContent = [header, ...rows]
        let csvContent = 'data:text/csv;charset=utf-8,' +
          allContent.map(e => e.join(',')).join('\n')
        var encodedUri = encodeURI(csvContent)
        var link = document.createElement('a')
        let currDate = new Date().toISOString()
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', `${this.currSelectionSentence.replaceAll(/<.+?>/g, '')}-${currDate}.csv`)
        document.body.appendChild(link) // Required for FF

        link.click()
      } catch (err) {
        console.warn('Export csv file faild!', err)
      }
      this.isExportCsving = undefined
    },
    defaultState () {
      this.currSelectionSentence = 'Report'
      this.isLoading = true
      this.structuredData = []
      this.displayProperty['room'] = ''
      this.displayProperty['location'] = ''
      this.displayProperty['alert_type'] = ''
      this.displayProperty['time_date'] = ''
      this.displayProperty['custom_date'] = [new Date().toISOString(), new Date().toISOString()]
      this.displayProperty['resolution'] = ''
      this.displayProperty['time_of_day'] = ''
      this.saveSelectedValue('alert_type', [])
      this.saveSelectedValue('room', [])
      this.saveSelectedValue('location', [])
      this.saveSelectedValue('time_date', [])
      this.saveSelectedValue('resolution', [])
      this.saveSelectedValue('time_of_day', [])
      this.filterOpts['time_date'].forEach(item => {
        if (item.id === 'past_hour') {
          item.selected = true
        } else {
          item.selected = false
        }
      })
    },
    async reloadData () {
      this.defaultState()
      await this.fetchContainers() // Fetched for Room Filter List
      await this.dbLoadData(true)
      this.changeSentence = !this.changeSentence
    },
    closeCustomDateFilter () {
      this.isCustomDateOpened = false
    },
    paginationChanged (event) {
      if (event) {
        this.reRunRequest()
      }
    }
  },
  watch: {
    async getCurrentCustomer (newVal) {
      if (newVal) {
        await this.reloadData()
      }
    },
    async getCurrentFulfiller (newVal) {
      if (newVal) {
        await this.reloadData()
      }
    },
    getContainerInfo (newVal) {
      let roomData = [] // = [{ name: 'All Rooms', id: 'all_rooms', selected: true }]
      for (const container in newVal) {
        roomData.push(newVal[container])
      }
      this.structuredLocations = roomData
    },
    changeSentence (newVal) {
      let currSentence = 'Report'
      if (!this.filters || this.filters.length === 0) return
      for (const item of this.filters) {
        let foundMatch = false
        if (this.filterOpts[item.typeId] && this.filterOpts[item.typeId].length > 0) {
          for (const opt of this.filterOpts[item.typeId]) {
            if (opt.selected && !currSentence.includes(opt.name)) {
              let name = opt.name
              if (opt.id === 'custom_date') {
                let fromDate = new Date(this.displayProperty['custom_date'][0])
                fromDate.setHours(0, 0, 0, 0)
                name = 'from ' + this.convertDate(fromDate).replace(/(.+),(.+)/, '<b>$1</b>,$2')
                if (this.displayProperty['custom_date'][1]) {
                  let toDate = new Date(this.displayProperty['custom_date'][1])
                  toDate.setHours(23, 59, 59, 999)
                  name += ' to ' + this.convertDate(toDate).replace(/(.+),(.+)/, '<b>$1</b>,$2')
                }
              }
              currSentence += !foundMatch ? ' - ' + name : ', ' + name
              foundMatch = true
            }
          }
        }
        foundMatch = false
      }
      this.currSelectionSentence = currSentence
    },
    rawData (newVal) {
      this.structuredData = []
      this.structuredData = this.filterAlertData(newVal)
    },
    sortDescByTime () {
      this.reRunRequest()
    }
  }
}

</script>
