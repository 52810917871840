<template>
  <span class="intuitive-flex" v-if="!restrictedAccess">
    <v-btn
      small
      class="ma-2"
      elevation="2"
      :loading="loading.includes(ind)"
      :color="ind === editingRow ? 'primary' : 'info'"
      @click="editingRow === ind ? updatePause(ind) : editRow(ind)"
      @keydown="editingRow === ind ? updatePause(ind) : editRow(ind)"
    >
      {{ind === editingRow ? 'Save' : 'Edit'}}
    </v-btn>
    <transition name="fade">
      <span v-if="ind === editingRow">
        <v-btn color="primary" elevation="2" class="ma-2" small @click="editRow(ind)" @keydown="editRow(ind)">
          Cancel
        </v-btn>
        <v-btn color="primary" elevation="2" fab icon small @click="trashRow(ind)" @keydown="trashRow(ind)">
          <Icon iconName="trash-fill" size="15px" color="grey" :is-bootstrap="true" />
        </v-btn>
      </span>
    </transition>
  </span>
</template>

<script>
import Icon from '@/components/elements/Icon'
export default {
  name: 'PauseCrudBtns',
  components: {
    Icon
  },
  props: {
    ind: { type: Number },
    updatePause: { type: Function },
    editRow: { type: Function },
    trashRow: { type: Function },
    loading: { type: Array },
    trashability: { type: Boolean },
    editingRow: { type: Number },
    restrictedAccess: { type: Boolean }
  }
}
</script>
